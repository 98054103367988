<script>
  import Map from "./Map.svelte";
  import Scroll from "./Scroll.svelte";
</script>

<style>
  section {
    text-align: center;
  }

  h2 {
    font-weight: 500;
  }
  p {
    color: #a0aec0;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .website-link {
    color: #2a5bd7;
  }

  @media (max-width: 991px) {
    section {
      padding: 16px;
    }
    h2 {
      font-size: 1.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }
</style>

<section>
  <h2>
    Get to know your
    <span class="website-link">idfk.lol</span>
    Links
  </h2>
  <p>Uncover the number of clicks and where they come from</p>
  <Scroll />
  <Map />
</section>
