<script>
  import Modal from "svelte-simple-modal";
  import Logo from "../../public/images/logo.svg";
  import Donate from "./Donate.svelte";
</script>

<style>
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
  }
  .logo {
    max-width: 100px;
  }

  @media (max-width: 991px) {
    .logo {
      max-width: 80px;
      height: 32px;
    }
  }
</style>

<div class="container pt-3-lg">
  <nav>
    <div class="logo">
      <Logo />
    </div>

    <Modal>
      <Donate />
    </Modal>
  </nav>
</div>
