<script>
  import BannerSvg from "../../public/images/banner.svg";
</script>

<style>
  section {
    display: flex;
    align-items: center;
    height: 55%;
  }

  .banner-svg {
    width: 140%;
    flex: 1;
  }
  .banner-text {
    flex: 1;
    padding-right: 2rem;
  }
  .banner-text h1 {
    color: #252628;
    font-size: 46px;
    font-weight: 700;
    margin: 0;
  }

  .banner-text h2 {
    color: #a0aec0;
    font-size: 22px;
    font-weight: 400;
    margin: 1rem 0;
    line-height: 35px;
    max-width: 500px;
  }

  .get-analytics {
    display: inline-block;
    padding: 0.8rem 2rem;
    background: #2a5bd7;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .get-analytics:not(:disabled):active,
  .get-analytics:not(:disabled):hover,
  .get-analytics:not(:disabled):hover {
    background-color: rgb(27, 57, 135);
    filter: brightness(140%);
  }

  .get-analytics:not(:disabled):focus {
    box-shadow: 0 0 0 2px rgba(206, 218, 250, 1);
  }

  @media (max-width: 991px) {
    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: unset;
      margin-top: 0;
      height: unset;
    }
    .banner-text h1 {
      font-size: 2rem;
      line-height: 38px;
    }
    .banner-text h2 {
      font-size: 1.2rem;
      line-height: 26px;
      margin: 1rem 0 1.5rem;
    }

    .banner-svg {
      width: 80%;
      order: 1;
      height: 260px;
      margin: 0 auto;
    }
    .banner-text {
      order: 2;
      margin: 1rem 0;
    }
    .get-analytics {
      margin-bottom: 0;
    }
  }
</style>

<section class="container">
  <div class="banner-text">
    <h1>Say hello to cleaner URLs</h1>
    <h2>
      URLs are no love letters. Create short URLs & track them in real time!
    </h2>
    <a class="get-analytics" href="#analytics-input">Get Analytics</a>
  </div>
  <div class="banner-svg">
    <BannerSvg />
  </div>
</section>
