<script>
  import Arrow from "../../public/images/arrow.svg";
</script>

<style>
  div {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 991px) {
    div {
      display: none;
    }
  }
</style>

<div>
  <Arrow />
</div>
